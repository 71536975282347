








import { Vue, Component } from 'vue-class-decorator'

@Component({
})
export default class FullPage extends Vue {
}
